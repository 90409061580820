<template>
  <v-card>
    <v-card-title class="text-h6">{{ $t("labels.roll_stamp") }}</v-card-title>
    <v-card-text>{{ $t("messages.enter_stamp_code_and_reason") }}</v-card-text>

    <v-card-text>
      <v-row dense>
        <v-col cols="6">
          <select-warehouse-model
            v-model="id_warehouse"
            class="mb-3"
          ></select-warehouse-model>
        </v-col>
        <v-col cols="6">
          <select-pos-model v-model="id_pos" class="mb-3"></select-pos-model>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="codes"
            class="c-input-xs"
            rows="3"
            label="UIDs"
            placeholder="uid1;uid2;uid3;..."
            autofocus
            dense
            outlined
            clearable
            hide-details
            @paste.prevent.stop="onUidPaste"
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="reason"
            class="c-input-xs"
            rows="3"
            :label="$t('labels.roll_reason')"
            dense
            outlined
            clearable
            hide-details
          ></v-textarea>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn
        color="green darken-1"
        text
        @click="confirm"
        :disabled="isDisabledBtn"
      >
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "RollGoodsUid",
  data: () => ({
    codes: "",
    reason: null,
    id_warehouse: null,
    id_pos: null,
    isLoading: false,
  }),
  computed: {
    isDisabledBtn() {
      return (
        !this.codes || !this.reason || (!this.id_warehouse && !this.id_pos)
      );
    },
  },
  mounted() {},
  methods: {
    onUidPaste(evt) {
      const value = evt.clipboardData.getData("text");
      if (value.includes("\n")) {
        const codeSplit = value.split("\n").filter((v) => v);
        this.codes = codeSplit.join(";");
      } else if (value.includes(" ")) {
        const codeSplit = value.split(" ").filter((v) => v);
        this.codes = codeSplit.join(";");
      } else {
        this.codes += value;
      }
    },
    async confirm() {
      if (this.isDisabledBtn) {
        return false;
      }

      if (this.isLoading) {
        this.codes = "";
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/goods/v1/uid-roll-multiple", {
          codes: this.codes,
          reason: this.reason,
          id_warehouse: this.id_warehouse,
          id_pos: this.id_pos,
        });
        this.isLoading = false;
        this.codes = "";
        this.$root.$emit("playSuccessAudio");
        this.$vToastify.success(this.$t("messages.uid_roll_success"));
        this.$emit("refreshData");
        this.cancel();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        this.codes = "";
        this.$root.$emit("playErrorAudio");
      }
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped></style>
